import { usePathname } from "next/navigation";
import React, { BaseSyntheticEvent, ChangeEvent, useCallback } from "react";
import { dictionaryRoutes } from "@/helpers/constant";
import * as Sentry from "@sentry/nextjs";
import { DateFilter } from "@/lib/redux/slices/filtersSearchScreenSlice/types";

export const requestFilters = <T = string | number>(
  key: string,
  operandoValor: T | DateFilter,
  operandoTipo = "0",
  operador = "4",
) => {
  try {
    return {
      [key]: [
        {
          operandoTipo,
          operandoValor,
          operador,
        },
      ],
    };
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const getRequestFiltersIdStockAndIdIssuer = (
  pathName: string,
  ids: {
    idEmitente?: number;
    idDeposito?: number;
  },
) => {
  const { idEmitente, idDeposito } = ids;

  return {
    ...([
      "/vendas",
      "/nfe",
      "/nfce",
      "/nfse",
      "/compras",
      "/caixas",
      "/bancos",
      "/maquinascartao",
      "/contasreceber",
      "/contaspagar",
    ].some((path) => pathName.includes(path))
      ? {
          ...requestFilters("idEmitente", String(idEmitente), "0", "4"),
        }
      : {}),
    ...(["/produtos", "/servicos"].some((path) => pathName.includes(path))
      ? {
          ...requestFilters("idDeposito", String(idDeposito), "0", "4"),
        }
      : {}),
  };
};

export const valueExists = (value: any) => {
  if (!["number", "string"].includes(typeof value)) return false;

  return (
    value !== null &&
    typeof value !== "undefined" &&
    String(value).trim() !== ""
  );
};
export const usePathnameVerify = () => {
  const currentPathname = usePathname();
  const isActivePath = useCallback(
    (path: string) => {
      return currentPathname === path;
    },
    [currentPathname],
  );
  return { isActivePath, currentPathname };
};

export function adjustPathNameByRouter(router: string): string {
  const titles: { [p: string]: string } = Object.entries(
    dictionaryRoutes,
  ).reduce(
    (previousValue, [key, value]) => ({
      ...previousValue,
      [String(key).toLowerCase()]: value,
    }),
    {},
  );
  return titles[router] || router;
}

export function preventPropagation(
  cb: (e?: BaseSyntheticEvent | any) => Promise<void> | void,
) {
  return (event: BaseSyntheticEvent | any) => {
    const result = cb(event);
    event.preventDefault();
    event.stopPropagation();
    return result;
  };
}

export function defaultValue(valueToDefault: string | number) {
  return (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      event.target.value = String(valueToDefault);
    }
  };
}

export const onlyNumbers: {
  onKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
} = {
  onKeyDown: (event) => {
    // Obtém o código da tecla pressionada
    const charCode = event.key;

    // Permite apenas números (0-9) e as teclas de controle
    if (
      isNaN(Number(charCode)) &&
      !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(
        charCode,
      )
    ) {
      event.preventDefault();
    }
  },
};

export function removeEmptyValuesOfObject<T = object>(obj: T) {
  let result = {};
  for (const [key, value] of Object.entries(obj as object)) {
    if (value !== undefined && value !== null && value !== "") {
      result = { ...result, [key]: value };
    }
  }
  return result;
}

export function querySelector<E = Element>(querySelectorString: string): E {
  return document.querySelector(querySelectorString) as E;
}

export function handlerError<T>(
  cb: () => T,
  defaultValue: T,
  debuggerParams: object,
): T {
  try {
    return cb();
  } catch (error) {
    !isDev() &&
      Sentry.captureException(error, {
        extra: {
          ...debuggerParams,
        },
      });
    console.error("Error handled:", error, {
      ...debuggerParams,
    });
    return defaultValue;
  }
}

export function isDev(): boolean {
  return process.env.NODE_ENV === "development";
}
